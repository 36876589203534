import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "tyro-integration-alternatives",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tyro-integration-alternatives",
        "aria-label": "tyro integration alternatives permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tyro integration alternatives`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Function`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Tyro Health Transaction SDK`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Tyro iClient`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Tyro Terminal Adapter (TTA)`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Designed for`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cloud hosted and browser-based healthcare and practice management (PMS) applications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cloud hosted and browser-based POS and PMS applications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Windows based POS and PMS applications`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Library`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Javascript - NPM or Yarn library`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Javascript library`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Windows .net library`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Supported industry use cases`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Healthcare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Retail, Hospitality, Travel, Healthcare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Retail, Hospitality, Travel, Healthcare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Terminal setup and configuration`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Via Medipass portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Within iClient library, can be integrated function in PMS/POS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Within TTA library, can be integrated function in PMS/POS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><em parentName="strong">{`EFTPOS functions`}</em></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Purchases - card present`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Purchases - card-not-present (SMS `}{`&`}{` payment link requests)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Refunds`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}{`*`}{` (only via Medipass portal)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Purchase with cash out`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Integrated receipts`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Reporting`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Reconciliation and settlement`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N (manual only)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Multi-merchant`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tipping / Bar tabs`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Integrated surcharging`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><em parentName="strong">{`Healthcare functions`}</em></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medicare Bulk Billing`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}{`*`}{` (no integrated reporting)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}{`*`}{` (no integrated reporting)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medicare Patient Claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y (EasyClaim)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y (EasyClaim)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medicare EasyClaim`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Medicare patient validation `}{`&`}{` Concession checks`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Private Health Insurance Claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y - HealthPoint terminal `}{`&`}{` HICAPS digital claims`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Private Health Insurance Quotes/Rebate estimates`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DVA claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`iCare claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Comcare claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Worksafe VIC claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`WorkCover QLD claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`NDIS claims`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Overseas cover medical claims (NIB)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Business support model`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Built in live chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Telephone/email support`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Telephone/email support`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Other notes`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supports synchronous `}{`&`}{` asynchronous (via webhooks) transactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Synchronous transaction types`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Synchronous transaction types`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Selection of TIDs and MIDs`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manual or default mapped to provider numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manual`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Manual`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note that a separate set of RESTful JSON APIs and batch file processing is available for select functions via Tyro Health. This guide will only cover integration via the Tyro Health Transaction SDK. `}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      